import React, { useState, useMemo, useCallback, memo, useEffect } from "react";
import {
  ChannelIdentifier,
  ProvisionalContent,
  ChannelAccount,
  ProvisionalBody,
  ProvisionalContentMedium,
  ProvisionalPost,
  Channel,
} from "../types";
import { ChannelLogo } from "./ChannelSelector";

import Modal from "./Modal";
import { HiddenInput } from "./DynamicFormGenerator";
import BodyEditor from "./BodyEditor";
import { PaperAirplaneIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import usePubValidation from "./usePubValidation";
import ErrorDisplayer from "./ErrorDisplayer";
import { UsersIcon } from "@heroicons/react/24/solid";
import Tooltip from "./Tooltip";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import PostPreview from "./PostPreview";

type Props = {
  bodies: ProvisionalBody[];
  content_media: ProvisionalContentMedium[];
  content: ProvisionalContent;
  profile_picture: string;
  variables: Record<string, string | null>;
  inserted_html?: string;
  channel_constraints: Record<
    ChannelIdentifier,
    ChannelAccount["channel_constraints"]
  >;
};

const channelNames = {
  facebook: "Facebook",
  google_my_business: "Google My Business",
  pinterest: "Pinterest",
  twitter: "X",
  linkedin: "LinkedIn",
  tiktok: "TikTok",
  instagram: "Instagram",
};

const CCForm = (props: Props) => {
  // ### Tabs
  const mobileTabs = [
    { label: "Contenu", value: "content" },
    { label: "Publication", value: "publication" },
  ];
  const [activeMobileTab, setActiveMobileTab] = useState("content");

  const [hBodies, setHBodies] = useState<Record<number, ProvisionalBody>>(
    Object.fromEntries(props.bodies.map((b) => [b.provisional_id, b]))
  );

  const channels = [
    "facebook",
    "twitter",
    "linkedin",
    "instagram",
    "tiktok",
    "pinterest",
    "google_my_business",
  ] as ChannelIdentifier[];

  const [showModalForBodyProvId, setShowModalForBodyProvId] = useState<
    null | number
  >(props.content.body_channel_groups_attributes[0].body_provisional_id);

  // Post
  const [content, setContent] = useState<ProvisionalContent>(props.content);

  // On garde en mémoire les bcgs originaux pour l'édition
  const originalBcgIds = useMemo(
    () =>
      props.content.body_channel_groups_attributes
        .filter((b) => b.id)
        .map((b) => b.id),
    []
  ) as number[];

  // Content Media
  const [hContentMedia, setHContentMedia] = useState<
    Record<number, ProvisionalContentMedium>
  >(
    Object.fromEntries(props.content_media.map((cm) => [cm.provisional_id, cm]))
  );

  const switchBody = (
    targetIds: ChannelIdentifier[],
    bodyId: ProvisionalBody["provisional_id"]
  ) => {
    // on trouve le bcg associé au body et on manipule ses channels

    const body_channel_groups_attributes: ProvisionalContent["body_channel_groups_attributes"] =
      [];

    content.body_channel_groups_attributes.forEach((bcg) => {
      if (bcg.body_provisional_id === bodyId) {
        if (targetIds.length) {
          body_channel_groups_attributes.push({ ...bcg, channels: targetIds });
        }
      } else {
        const remainingChans = bcg.channels.filter(
          (c) => !targetIds.includes(c)
        );
        if (remainingChans.length) {
          body_channel_groups_attributes.push({
            ...bcg,
            channels: remainingChans,
          });
        }
      }
    });

    setContent((prev) => ({ ...prev, body_channel_groups_attributes }));

    // On supprime les bodies sans bcgs (et donc sans channels)
    const bcgBPid = body_channel_groups_attributes?.map(
      (bcg) => bcg.body_provisional_id
    );
    setHBodies((hBodies) => {
      const nextBodies = Object.fromEntries(
        Object.entries(hBodies).filter(([pid, _]) =>
          bcgBPid.includes(parseInt(pid))
        )
      );
      return nextBodies;
    });
  };

  // les bodies dans l'ordre pour itérer
  const sortedBodyKeys = useMemo(() => {
    return Object.keys(hBodies)
      .map((p) => parseInt(p))
      .sort();
  }, [Object.keys(hBodies).length]); // le nombre de body a changé

  // Callback avant la suppression d'un body
  const onBodyRemoval = useMemo(
    () => (bodyPid: number) => {
      const body_channel_groups_attributes =
        content.body_channel_groups_attributes.filter(
          (bcg) => bcg.body_provisional_id !== bodyPid
        );
      // On ouvre l'onglet du dernier body
      const lastBodyId = Object.keys(hBodies)
        .filter((bId) => parseInt(bId) !== bodyPid)
        .at(-1) as string;
      setDesktopActiveBody(parseInt(lastBodyId));
      setContent((prev) => ({ ...prev, body_channel_groups_attributes }));
    },
    [content]
  );

  // Pour la suppression d'un body vide
  const destroyBody = useCallback((bodyPid: number) => {
    // on enlève le bcg associé
    setContent((prev) => ({
      ...prev,
      body_channel_groups_attributes:
        prev.body_channel_groups_attributes.filter(
          (bcg) => bcg.body_provisional_id !== bodyPid
        ),
    }));
    setHBodies(({ [bodyPid]: _, ...keep }) => {
      const lastBodyId = Object.keys(keep)
        .filter((bId) => parseInt(bId) !== bodyPid)
        .at(-1) as string;
      setDesktopActiveBody(parseInt(lastBodyId));
      return keep;
    });
  }, []);

  // Callback après la duplication d'un body
  const onBodyDuplication = useCallback(
    (bodyPid: number) => {
      // on crée un bcg pour lui
      content.body_channel_groups_attributes.push({
        channels: [],
        body_provisional_id: bodyPid,
      });
      setShowModalForBodyProvId(bodyPid);
      setDesktopActiveBody(bodyPid);
    },
    [hBodies]
  );

  // Which body is active on desktop
  const [desktopActiveBody, setDesktopActiveBody] = useState(sortedBodyKeys[0]);
  const isActive = (bPid: number) => bPid === desktopActiveBody;

  // Prevent recreating the selector component on renders
  const memoizedSelectorRenderer = useMemo(
    () => (bPid: ProvisionalBody["provisional_id"], buttonUI: JSX.Element) => {
      return (
        <ChannelSelector
          bPid={bPid}
          content={content}
          destroyEmptyBody={destroyBody}
          channels={channels}
          switchBody={switchBody}
          showModalForBodyProvId={showModalForBodyProvId}
          setShowModalForBodyProvId={setShowModalForBodyProvId}
          buttonUI={buttonUI}
        />
      );
    },
    [content, showModalForBodyProvId]
  );

  const deleteChannelGroup = (channels: ChannelIdentifier[]) => {
    document
      ?.getElementById("main-container")
      ?.scroll({ top: 0, behavior: "smooth" });

    let newBodyPids: number[] = []; // store surviving bodies

    setContent((prev) => {
      const body_channel_groups_attributes = prev.body_channel_groups_attributes
        .map((bcg) => ({
          ...bcg,
          channels: bcg.channels.filter((c) => !channels.includes(c)),
        }))
        .filter((bcg) => bcg.channels.length != 0);
      newBodyPids = body_channel_groups_attributes.map(
        (p) => p.body_provisional_id
      );
      return { ...prev, body_channel_groups_attributes };
    });

    setTimeout(() => {
      // on nettoie les bodies
      Object.entries(hBodies).forEach(([pid, body]) => {
        const iPid = parseInt(pid);
        if (!newBodyPids.includes(iPid)) {
          destroyBody(iPid);
        }
      });
    }, 50);
  };

  const createNewBodyForChannelGroup = (
    channels: ChannelIdentifier[],
    error: string
  ) => {
    // en réalité toujours une seule
    const channel = channels[0];
    const constraint = props.channel_constraints[channel];
    const bcg = content.body_channel_groups_attributes.find((bcg) =>
      bcg.channels.includes(channel)
    );

    if (!constraint || !bcg) return;

    const body = hBodies[bcg.body_provisional_id];

    let newBodyPid: number | null = null;
    let body_media_attributes = body.body_media_attributes.map(
      ({ id, ...b }) => ({ ...b })
    );
    let message = body.message || "";

    if (error == "character_limit") {
      message = message.substring(0, constraint[error] || 0);
    }
    if (error == "picture_limit") {
      body_media_attributes = body_media_attributes.slice(
        0,
        constraint[error] || 0
      );
    }
    setHBodies((prev) => {
      newBodyPid =
        Math.max(...Object.keys(prev).map((k) => parseInt(k)), 0) + 1;
      return {
        ...prev,
        [newBodyPid]: {
          ...body,
          message,
          provisional_id: newBodyPid,
          body_media_attributes,
        },
      };
    });
    setContent((prev) => ({
      ...prev,
      body_channel_groups_attributes: [
        ...prev.body_channel_groups_attributes.map((bcg) => ({
          ...bcg,
          channels: bcg.channels.filter((c) => !channels.includes(c)),
        })),
        {
          channels: [channel],
          body_provisional_id: newBodyPid,
        } as ProvisionalContent["body_channel_groups_attributes"][0],
      ],
    }));

    document
      ?.getElementById("main-container")
      ?.scroll({ top: 0, behavior: "smooth" });
    // NOTE: Dans certains cas ici on a un body fantôme, chercher
  };

  const validationObject = usePubValidation({
    hBodies,
    hContentMedia,
    bcgs: content.body_channel_groups_attributes,
    channelConstraints: props.channel_constraints,
    deleteGroup: deleteChannelGroup,
    createNewBodyForGroup: createNewBodyForChannelGroup,
  });

  return (
    <div id="PostStateManager" className="w-full space-y-12">
      <ul className="flex border-b gap-5 lg:hidden">
        {mobileTabs.map((tab) => (
          <li
            key={tab.value}
            className={`text-base p-2 border-b-2 ${
              activeMobileTab === tab.value
                ? "border-brand_main text-brand_main"
                : "text-gray-500 border-gray-400 cursor-pointer"
            }`}
            onClick={() => setActiveMobileTab(tab.value)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      <div
        className={`${
          activeMobileTab !== "content" && "hidden"
        } lg:block space-y-12 relative`}
      >
        <div className="">
          <div className="hidden z-[5] lg:flex -mb-1 gap-1 relative border-limited w-full overflow-x-auto">
            {sortedBodyKeys?.map((bPid) => {
              const active = isActive(bPid);
              return (
                <div
                  key={`btn-${bPid}`}
                  className={`z-0 p-2 px-3 rounded-t-md border-t border-l border-r ${
                    active ? "bg-white " : "bg-gray-100 cursor-pointer"
                  }`}
                  onClick={() => setDesktopActiveBody(bPid)}
                >
                  <ChannelsUiElement
                    bPid={bPid}
                    content={content}
                    channels={channels}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex flex-col gap-12">
            {sortedBodyKeys?.map((bPid, index) => {
              return (
                <BodyEditor
                  key={bPid}
                  index={index}
                  body={hBodies[bPid]}
                  isLast={sortedBodyKeys.length <= 1}
                  isActive={isActive(bPid)} // setDesktopActiveBody
                  setHBodies={setHBodies}
                  onRemoval={onBodyRemoval}
                  profile_picture={props.profile_picture}
                  onDuplication={onBodyDuplication}
                  hContentMedia={hContentMedia}
                  setHContentMedia={setHContentMedia}
                  targetsSelectorRenderer={memoizedSelectorRenderer}
                />
              );
            })}
          </div>
        </div>
        <div className="flex justify-end sticky bottom-0 lg:hidden">
          <div className="backdrop-blur-sm pb-5 pt-3 pl-3">
            <button
              type="button"
              className="m-2 flex rounded-lg py-3 pl-5 pr-3 bg-brand_main hover:bg-brand_darker font-medium text-white gap-1"
              onClick={() => {
                document
                  ?.querySelector("#main-container")
                  ?.scrollTo({ top: 0, left: 0 });
                setActiveMobileTab("publication");
              }}
            >
              Suivant
              <ChevronRightIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          activeMobileTab !== "publication" && "hidden"
        } lg:block space-y-12`}
      >
        <div className="flex flex-col gap-5 p-5 bg-white rounded shadow">
          <div className="">
            {/*Object.keys(validationObject.errors).length > 0 && (
              <div className="flex flex-col gap-3">
                <div className="flex flex-col flex-wrap gap-5 sm:flex-row">
                  <div className="flex flex-col gap-1 p-3 text-red-500 border-l-2 border-red-500">
                    <div className="flex gap-1">
                      <ExclamationCircleIcon className="size-6" />
                      <p>{Object.entries(errors).length} erreurs</p>
                    </div>
                    <ul>
                      {
                        errors.flatMap(({ channel, messages }) =>
                          messages?.map((message) => `${channelNames[channel]} ${message.message}`)
                        ).map((message) => (
                          <li>{message}</li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>
            )*/}
            {/* insertion du html externe*/}
            <div
              dangerouslySetInnerHTML={{ __html: props.inserted_html || "" }}
            />
            <h3 className="text-lg font-medium text-gray-900">
              Prévisualisation
            </h3>

            <div className="grid grid-cols-1 xl:grid-cols-2 lg:m-8 gap-8  sm:flex-row items-center snap-mandatory snap-x">
              {content.body_channel_groups_attributes
                .flatMap((bcg) =>
                  bcg.channels.map(
                    (c) =>
                      [c, bcg.body_provisional_id] as [
                        ChannelIdentifier,
                        number
                      ]
                  )
                )
                // here we order by errors
                .map(([channel, bPid]) => {
                  // Manière pas ouf de retrouver le channelidentifier concaténé
                  const errors = Object.entries(validationObject.errors)
                    .filter(([k, v]) => k.split("|")[0] == channel)
                    .map(([_k, v]) => v);
                  return (
                    <PostPreview
                      key={channel}
                      errors={errors}
                      body={hBodies[bPid]}
                      hContentMedia={hContentMedia}
                      variables={props.variables}
                      header={
                        <div className="flex items-center rounded justify-between w-full text-gray-500 text-sm bg-gray-50 p-3">
                          <ChannelLogo
                            identifier={channel}
                            active={true}
                            size={6}
                          />
                        </div>
                      }
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div className="fixed flex flex-col gap-3 h-fit bottom-6 right-8">
          <ErrorDisplayer validationObject={validationObject} />
          <div className="flex justify-end">
            <div className="backdrop-blur-sm p-2">
              <button
                type="submit"
                className="flex gap-3 p-3 pr-5 font-medium text-white rounded-lg cursor-pointer bg-brand_main hover:bg-brand_darker"
              >
                <PaperAirplaneIcon className="size-6" />
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Hidden inputs */}
      <BcgInputs
        bcgs={content.body_channel_groups_attributes}
        originalBcgIds={originalBcgIds}
      />
      <ContentMediaInputs hContentMedia={hContentMedia} />
    </div>
  );
};

// Ajoute les inputs pour créer, modifier et détruire les pocs
const BcgInputs = memo(
  ({
    bcgs,
    originalBcgIds,
  }: {
    originalBcgIds: number[];
    bcgs: ProvisionalContent["body_channel_groups_attributes"];
  }) => {
    const prefix = "content[body_channel_groups_attributes]";
    // const channelAccountIds = bcgs.map(poc => poc.channel_account_id)
    const destIndex = bcgs.length + 1;
    const destroyables = originalBcgIds.filter(
      (bcg) => !bcgs.map((b) => b.id).includes(bcg)
    );

    return (
      <>
        {" "}
        {bcgs.map(({ channels, body_provisional_id: bPid, id }, i) => (
          <div key={bPid}>
            <HiddenInput name={`${prefix}[${i}][id]`} value={id} />
            <HiddenInput
              name={`${prefix}[${i}][body_provisional_id]`}
              value={bPid}
            />
            {channels.map((c) => (
              <HiddenInput
                key={c}
                name={`${prefix}[${i}][channels][]`}
                value={c}
              />
            ))}
          </div>
        ))}
        {destroyables.map((id, i) => (
          <div key={id}>
            <HiddenInput name={`${prefix}[${i + destIndex}][id]`} value={id} />
            <HiddenInput
              name={`${prefix}[${i + destIndex}][_destroy]`}
              value={1}
            />
          </div>
        ))}
      </>
    );
  }
);

const ContentMediaInputs = memo(
  ({
    hContentMedia,
  }: {
    hContentMedia: Record<number, ProvisionalContentMedium>;
  }) => {
    const prefix = "content_media[]";
    return (
      <>
        {Object.values(hContentMedia).map((cm) => (
          <div key={`${cm.provisional_id}_body_input`}>
            <HiddenInput
              name={`${prefix}[provisional_id]`}
              value={cm.provisional_id}
            />
            <HiddenInput name={`${prefix}[id]`} value={cm.id} />
            {cm.serialized_file.signature && (
              <HiddenInput
                name={`${prefix}[medium]`}
                value={`${cm.serialized_file.identifier}#${cm.serialized_file.signature}`}
              />
            )}
          </div>
        ))}
      </>
    );
  }
);

export default CCForm;

const channelsForBPid = (
  bPid: ProvisionalBody["provisional_id"],
  content: ProvisionalContent,
  channels: ChannelIdentifier[]
) =>
  content.body_channel_groups_attributes.find(
    ({ body_provisional_id }) => body_provisional_id === bPid
  )?.channels || ([] as ChannelIdentifier[]);

export const ChannelSelector = memo(
  ({
    bPid,
    content,
    channels,
    switchBody,
    showModalForBodyProvId,
    setShowModalForBodyProvId,
    destroyEmptyBody,
    buttonUI,
  }: {
    bPid: ProvisionalBody["provisional_id"];
    content: ProvisionalContent;
    channels: ChannelIdentifier[];
    switchBody: (
      targetsIds: ChannelIdentifier[],
      bodyId: ProvisionalBody["provisional_id"]
    ) => void;
    showModalForBodyProvId: number | null;
    setShowModalForBodyProvId: React.Dispatch<
      React.SetStateAction<number | null>
    >;
    destroyEmptyBody: (bPid: ProvisionalBody["provisional_id"]) => void;
    buttonUI?: JSX.Element;
  }) => {
    const selectedChannels = channelsForBPid(bPid, content, channels);
    // État interne des targets provenant du body dupliqué et des Channels Accounts cochés
    const [selectedTargets, setSelectedTargets] = useState(selectedChannels);
    const hasChannels = useMemo(
      () =>
        content.body_channel_groups_attributes.some(
          (bcg) => bcg.body_provisional_id === bPid
        ),
      [content]
    );

    // Message d'erreur si aucune target cochée
    const [error, setError] = useState({ status: false, message: "" });

    // Pour une raison mystérieuse,
    // la synchronisation de l'état ne fonctionne pas
    // On est obligés de forcer la mise à jour
    useEffect(() => {
      setSelectedTargets(channelsForBPid(bPid, content, channels));
    }, [content]);

    const resetError = () => setError({ status: false, message: "" });

    const onAction = (type: "cancel" | "validate") => {
      setError({ status: false, message: "" });
      if (type === "validate") {
        if (!selectedTargets.length)
          return setError({
            status: true,
            message: "Veuillez choisir au moins un compte",
          });
        switchBody(selectedTargets, bPid);
      }
      // Si on annule, on réinstalle l'état initial avec les données du back-end
      if (type === "cancel") {
        // Si le body n'a pas de channels, on le supprime
        if (!hasChannels) {
          destroyEmptyBody(bPid);
        }
        // On attend un peu pour pas que les checkbox se recochent tout de suite
        setTimeout(() => setSelectedTargets(selectedChannels), 500);
      }
      setShowModalForBodyProvId(null);
    };

    return (
      <div className="flex">
        <Modal open={bPid === showModalForBodyProvId} setOpen={() => null}>
          <div className="py-24">
            <div className="flex flex-col gap-4 p-6 my-auto bg-white rounded-lg shadow max-h-xl">
              <div className="flex flex-col items-start w-full space-y-4">
                <div className="text-start">
                  <h3 className="font-semibold text-md md:text-lg">
                    Réseaux sociaux
                  </h3>
                  <p className="text-sm text-gray-500 sm:text-base">
                    {selectedTargets.length} comptes sélectionnés
                  </p>
                </div>
                {error.status && (
                  <div>
                    <p className="text-sm text-red-500">
                      <ExclamationCircleIcon className="inline w-6 h-6" />
                      {error.message}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-6 p-6 overflow-y-auto border-gray-300 border-y">
                <div className="flex flex-col gap-3">
                  <div
                    className="clickable-text text-sm cursor-pointer"
                    onClick={() => {
                      resetError();
                      setSelectedTargets(channels); // Select all channels
                    }}
                  >
                    Tout sélectionner
                  </div>
                  <div
                    className="clickable-text text-sm cursor-pointer"
                    onClick={() => {
                      resetError();
                      setSelectedTargets([]); // Deselect all channels
                    }}
                  >
                    Tout déselectionner
                  </div>
                </div>

                {channels.map((channel) => {
                  const active = selectedTargets.includes(channel);

                  return (
                    <label className="flex space-x-2 items-center cursor-pointer">
                      <input
                        id={channel}
                        className="w-4 h-4 border-gray-300 rounded form-checkbox text-brand_main opacity-90 focus:border-brand_focus focus:ring focus:ring-brand_focus focus:bg-white"
                        value={channel}
                        type="checkbox"
                        checked={active}
                        onChange={(e) => {
                          resetError();
                          if (e.target.checked) {
                            setSelectedTargets((prev) => [...prev, channel]);
                          } else {
                            setSelectedTargets((prev) =>
                              prev.filter((c) => c !== channel)
                            );
                          }
                        }}
                      />
                      <ChannelLogo
                        identifier={channel}
                        active={active}
                        size={6}
                      />
                      <div className="font-medium text-gray-700">
                        {channelNames[channel]}
                      </div>
                    </label>
                  );
                })}
              </div>

              <div className="flex justify-between gap-1 sm:gap-5">
                <button
                  type="button"
                  className="btn-neutral"
                  onClick={() => onAction("cancel")}
                >
                  Annuler
                </button>
                <button
                  type="button"
                  className="px-4 py-2 text-base btn-brand-primary"
                  onClick={() => onAction("validate")}
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <button
          type="button"
          className=""
          onClick={() => setShowModalForBodyProvId(bPid)}
        >
          {buttonUI ? (
            buttonUI
          ) : (
            <>
              <div className="lg:hidden">
                <ChannelsUiElement
                  bPid={bPid}
                  content={content}
                  channels={channels}
                />
              </div>
              <Tooltip
                text="Sélectionner vos réseaux sociaux"
                position="bottomright"
              >
                <div className="hidden btn-neutral lg:flex">
                  <UsersIcon className="p-0 size-5 mr-2" /> Réseaux
                </div>
              </Tooltip>
            </>
          )}
        </button>
      </div>
    );
  }
);

export const ChannelsUiElement = memo(
  ({
    bPid,
    content,
    channels,
  }: {
    bPid: ProvisionalBody["provisional_id"];
    content: ProvisionalContent;
    channels: ChannelIdentifier[];
  }) => {
    const selectedChannels = channelsForBPid(bPid, content, channels);
    return (
      <div className="flex gap-1 font-medium text-gray-500">
        <div className="flex flex-row space-x-2">
          {selectedChannels.map((ch) => (
            <div key={ch} className="relative">
              <ChannelLogo identifier={ch} active={true} size={5} />
            </div>
          ))}
        </div>
      </div>
    );
  }
);
