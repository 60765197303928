import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["company", "input"]

  connect() {
    this.companyTargets.forEach(company => {
      company.style.display = "block"
    })
  }

  filter() {
    const searchTerm = this.inputTarget.value.toLowerCase().trim()
    
    this.companyTargets.forEach(company => {
      const companyName = company.querySelector('p').textContent.toLowerCase()
      
      if (searchTerm === '') {
        company.style.display = "block"
      } else if (companyName.includes(searchTerm)) {
        company.style.display = "block"
      } else {
        company.style.display = "none"
      }
    })
  }
}
