import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { getCSRFToken } from "./utils";
import { Body, ProvisionalBody, ProvisionalContentMedium } from "../types";
import ContentMediasUploader from "./ContentMediasUploader";
import BodyLink, { ctaOptions } from "./BodyLinks";
import {
  FaceSmileIcon,
  SparklesIcon,
  TrashIcon,
  EllipsisVerticalIcon,
  CheckIcon,
  CloudArrowDownIcon,
  PhotoIcon,
  XMarkIcon,
  UsersIcon,
  BeakerIcon,
} from "@heroicons/react/24/outline";
import useChatGPT from "../hook/useChatGpt";
import EmojiPicker from "@emoji-mart/react";
import Modal from "./Modal";
import CustomCloseButton from "./CustomCloseButton";
import ModalInput from "./ModalInput";
import OpenSmartLinkForm from "./OpenSmartLinkForm";
import { HiddenInput } from "./DynamicFormGenerator";
import Tooltip from "./Tooltip";
import { GoRepoForked } from "react-icons/go";
import CustomPopover from "./Popover";
import { MediaModal } from "./MediaModal";
import { useTextStyles } from "../hook/useTextStyles";

type Props = {
  setHContentMedia: React.Dispatch<
    React.SetStateAction<Record<number, ProvisionalContentMedium>>
  >;
  index: number;
  profile_picture: string;
  hContentMedia: Record<number, ProvisionalContentMedium>;
  canvaSession: any;
  isActive: boolean;
  isLast: boolean;
  isStephanePlazaAccount: boolean;
  body: ProvisionalBody;
  setHBodies: React.Dispatch<
    React.SetStateAction<Record<number, ProvisionalBody>>
  >;
  onRemoval?: (bPid: ProvisionalBody["provisional_id"]) => void;
  onDuplication?: (bPid: ProvisionalBody["provisional_id"]) => void;
  defaultPictureSearch: string;
  targetsSelectorRenderer: (
    bPid: ProvisionalBody["provisional_id"],
    buttonUI?: JSX.Element
  ) => JSX.Element;
};

export const BodyEditor = memo(
  ({
    body,
    setHBodies,
    onDuplication,
    onRemoval,
    isActive,
    canvaSession,
    profile_picture,
    isLast,
    isStephanePlazaAccount,
    targetsSelectorRenderer,
    hContentMedia,
    index,
    setHContentMedia,
    defaultPictureSearch,
  }: Props) => {
    const originalId = useMemo(() => body.id, []);

    // récupéré à la création et ne changera pas
    const originalCmPidByBmId = useMemo(
      () =>
        Object.fromEntries(
          body.body_media_attributes
            .filter((bm) => bm.id)
            .map((bm) => [bm.content_medium_provisional_id, bm.id])
        ),
      []
    ) as Record<number, number>;

    const updateBody = (data: Object) => {
      const pid = body.provisional_id;
      setHBodies((prev) => ({ ...prev, [pid]: { ...prev[pid], ...data } }));
    };

    const duplicateBody = ({ id, ...body }: ProvisionalBody) => {
      let newBodyPid: number | null = null;
      const body_media_attributes = body.body_media_attributes.map(
        ({ id, ...b }) => ({ ...b })
      );
      setHBodies((prev) => {
        newBodyPid =
          Math.max(...Object.keys(prev).map((k) => parseInt(k)), 0) + 1;
        return {
          ...prev,
          [newBodyPid]: {
            ...body,
            message: `${body.message || ""}`,
            provisional_id: newBodyPid,
            body_media_attributes,
          },
        };
      });
      // je reconnais que c'est tordu mais comme ça on a accès à la valeur
      setTimeout(() => {
        if (onDuplication && newBodyPid) {
          onDuplication(newBodyPid);
        }
      }, 0);
    };

    const deleteBody = (body: ProvisionalBody) => {
      onRemoval && onRemoval(body.provisional_id);
      // On remove à l'assignation
      setHBodies(({ [body.provisional_id]: _, ...keep }) => keep);
    };

    const beforeRemoveFn = (
      contentMediaId: ProvisionalContentMedium["provisional_id"],
      bodyId: ProvisionalBody["provisional_id"]
    ) => {
      setHBodies((prev) => ({
        ...prev,
        [bodyId]: {
          ...prev[bodyId],
          body_media_attributes: prev[bodyId]?.body_media_attributes?.filter(
            (cm) => cm.content_medium_provisional_id !== contentMediaId
          ),
        },
      }));
    };

    const afterAddFn = (
      contentMediaIds: ProvisionalContentMedium["provisional_id"][],
      bodyId: ProvisionalBody["provisional_id"]
    ) => {
      setHBodies((prev) => {
        const thisbody = prev[bodyId];
        const lastCMPosition =
          thisbody?.body_media_attributes?.slice(-1)[0]?.position || 0;
        const newBodyContentMedias = contentMediaIds.map((id, index) => ({
          content_medium_provisional_id: id,
          position: lastCMPosition + index + 1,
        }));
        return {
          ...prev,
          [bodyId]: {
            ...thisbody,
            body_media_attributes: [
              ...thisbody.body_media_attributes,
              ...newBodyContentMedias,
            ],
          },
        };
      });
    };

    // useEffect(() => {
    //   setTimeout(() => {
    //     setShowInfoMessage(false);
    //   }, 30000)
    // } , [])

    const afterMoveFn = (
      contentMediaId: number,
      newPosition: number,
      bodyProvisionalId: number
    ) => {
      setHBodies((prev) => {
        const thisBody = prev[bodyProvisionalId];
        const currentPosition = thisBody.body_media_attributes.find(
          (cm) => cm.content_medium_provisional_id === contentMediaId
        )?.position as number;
        // Donne un number > 0 si on attribue une position supérieure à la précdente
        const diffPosition = newPosition - (currentPosition as number);
        // console.log(`diffPosition: ${diffPosition}, currentPosition: ${currentPosition}, newPosition: ${newPosition}`)
        // Répartition des nouvelles positions
        const newBodyContentMedias = thisBody?.body_media_attributes?.map(
          (cm) => {
            // On édite une nouvelle poisition pour le CM modifié
            if (cm.content_medium_provisional_id === contentMediaId) {
              return {
                content_medium_provisional_id: contentMediaId,
                position: newPosition,
              };
            } else {
              // On change la position des autres CM
              // si on augmente la position du CM modifié
              if (diffPosition > 0) {
                // On ne touche pas à ceux qui sont avant la currentPosition et après la newPosition
                if (
                  cm.position < currentPosition &&
                  cm.position > newPosition
                ) {
                  return cm;
                }
                // On va prendre ceux qui sont entre la current (non inclu) et la new position (inclu)
                // Et on va les faire reculer (-1)
                if (
                  cm.position > currentPosition &&
                  cm.position <= newPosition
                ) {
                  return {
                    content_medium_provisional_id:
                      cm.content_medium_provisional_id,
                    position: cm.position - 1,
                  };
                }
              }
              if (diffPosition < 0) {
                // On ne touche pas à ceux qui sont avant la currentPosition et après la newPosition
                if (
                  cm.position > currentPosition &&
                  cm.position < newPosition
                ) {
                  return cm;
                }
                // On va prendre ceux qui sont entre la current (non inclu) et la new position (inclu)
                // Et on va les faire avancer (+1)
                if (
                  cm.position < currentPosition &&
                  cm.position >= newPosition
                ) {
                  return {
                    content_medium_provisional_id:
                      cm.content_medium_provisional_id,
                    position: cm.position + 1,
                  };
                }
              }
              return cm;
            }
          }
        );
        // On met a jour le body avec les nouvelles positions et on les trie
        const sortedCM = newBodyContentMedias.sort(
          (a, b) => a.position - b.position
        );
        return {
          ...prev,
          [bodyProvisionalId]: {
            ...thisBody,
            body_media_attributes: sortedCM,
          },
        };
      });
    };

    const computeFocus = (body: ProvisionalBody) => {
      const media = body.body_media_attributes?.map(
        (bma) => hContentMedia[bma.content_medium_provisional_id]
      );
      const linkUrl = body?.link_url;

      if (linkUrl) {
        return { focus: "link", accept: "image/*" };
      } else if (
        media.filter(
          (m) => !m?.destroy && m?.serialized_file.resource_type === "video"
        ).length > 0
      ) {
        return { focus: "video", accept: "video/*" };
      } else if (
        media.filter(
          (m) => !m?.destroy && m?.serialized_file.resource_type === "image"
        ).length > 1
      ) {
        return { focus: "images", accept: "image/*" };
      } else if (
        media.filter(
          (m) => !m?.destroy && m?.serialized_file.resource_type === "image"
        ).length > 0
      ) {
        return { focus: "image", accept: "image/*" };
      } else {
        return { focus: "none", accept: "image/*,video/*" };
      }
    };

    const { focus, accept } = computeFocus(body);
    const disabled = useMemo(
      () =>
        ["video", "link"].includes(focus) &&
        body.body_media_attributes.length > 0,
      [focus, body]
    );

    const [openCmModal, setOpenCmModal] = useState(false);
    const [isStory, setIsStory] = useState(false);
    const [openMediaModal, setOpenMediaModal] = useState(false);
    const [mediaModalSelectedImage, setMediaModalSelectedImage] =
      useState<File | null>(null);
    // TextArea Buttons
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const insert = (inserted: string) => {
      if (inputRef?.current) {
        const { selectionStart, selectionEnd, value } = inputRef.current;
        if (
          typeof selectionStart === "number" &&
          typeof selectionEnd === "number"
        ) {
          // typeOf car 0 est compté comme false
          const newValue = (inputRef.current.value = `${value.slice(
            0,
            selectionStart
          )}${inserted}${value.slice(selectionEnd)}`);

          setHBodies((prev) => ({
            ...prev,
            [body.provisional_id]: { ...body, message: newValue },
          }));
          inputRef?.current?.focus();
          setTimeout(() => {
            // Visiblement nécessaire
            if (inputRef.current) {
              inputRef.current.selectionStart =
                selectionStart + inserted.length;
              inputRef.current.selectionEnd = selectionStart + inserted.length;
              // if (props?.onInsertionCallback) {
              //   props.onInsertionCallback();
              // }
            }
          }, 10);
        }
      }
    };

    const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);
    const onEmojiSelect = (e: any) => {
      insert(String.fromCodePoint(parseInt(e.unified, 16)));
      setEmojiSelectorOpen(false);
    };
    // AI
    const [prompt, setPrompt] = useState("");
    const [gptSelectionStart, setGptSelectionStart] = useState<number | null>(
      null
    );
    const [textAfterSelection, setTextAfterSelection] = useState<string | null>(
      null
    );
    const [showAiModal, setShowAiModal] = useState<boolean>(false);
    const {
      error: gptError,
      fetchChatGPTResponse,
      isWriting: gptIsWriting,
      response: gptResponse,
    } = useChatGPT({
      apiRoute: "/bodies/openai_call",
      companyContent: window.location.href.includes("/company_contents/new")
    });
    // smartlink
    const [showSmartLinkModal, setShowSmartLinkModal] = useState(false);
    const [smartLinkUrl, setSmartLinkUrl] = useState("");
    const [smartLinkLoading, setSmartLinkLoading] = useState(false);
    const [smartLinkError, setSmartLinkError] = useState<string | null>(null);
    const toggleSmartlinkModal = () => {
      setShowSmartLinkModal(!showSmartLinkModal);
      setSmartLinkError(null);
      setSmartLinkUrl("");
    };

    // Si on a une réponse de ChatGPT, on insère le texte dans le message du body
    useEffect(() => {
      if (!gptIsWriting) {
        setGptSelectionStart(null);
      }
      if (gptIsWriting && gptSelectionStart === null) {
        setGptSelectionStart(inputRef?.current?.selectionStart as number);
        setTextAfterSelection(
          body?.message?.slice(inputRef?.current?.selectionStart as number) ||
            ""
        );
      }
      if (gptResponse?.text && gptSelectionStart !== null) {
        if (showAiModal) {
          setShowAiModal(false);
        } // on ferme la modale pour voir le beau texte s'écrire
        const start = body?.message?.slice(0, gptSelectionStart);
        // Mise à jour de l'état (setHBodies) avec le nouveau texte
        setHBodies((prev) => ({
          ...prev,
          [body.provisional_id]: {
            ...body,
            message: start + gptResponse?.text + textAfterSelection,
          },
        }));
      }
    }, [gptResponse, gptIsWriting]);

    // ça a l'air de marcher sans magiquement mais on met quand même :
    const [emojiData, setEmojiData] = useState({});
    useEffect(() => {
      (async () => {
        const response = await fetch(
          "https://cdn.jsdelivr.net/npm/@emoji-mart/data"
        );
        setEmojiData(await response.json());
      })();
    }, []);
    const insertionVariables = useMemo(() => {
      return [
        "$NOM",
        "$ENTREPRISE",
        "$SITE",
        "$MAIL",
        "$TEL",
        "$HASHTAG",
        "$CODE",
        "$LANDING",
        "$NEWSLETTER",
        "$APILINK",
        "$PROMOCODE",
        "$SPONSORLINK",
      ];
    }, []);

    // Links
    const removeLink = () =>
      updateBody({ link_url: "", link_description: "", link_cta: "" });

    // Utils
    const newPidForH = (h: Record<number, Object>) =>
      Math.max(...Object.keys(h).map((k) => parseInt(k)), 0) + 1;

    const replaceBodyWithRemote = async () => {
      setSmartLinkLoading(true);
      const response = await fetch("/bodies/generate_from_url", {
        headers: {
          "X-CSRF-Token": getCSRFToken(),
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify({ url: smartLinkUrl }),
      });
      const pid = body.provisional_id;
      if (!response.ok) {
        setSmartLinkError("Nous n'avons pas réussi à récupérer ce contenu");
      } else {
        const resBody: Body = await response.json();
        const body_media_attributes: ProvisionalBody["body_media_attributes"] =
          [];
        resBody.body_media?.forEach((bm) => {
          const newBm = {};
          // medium exists ?
          const existingCm = Object.values(hContentMedia).find(
            (cm) => cm.id === bm.content_medium.id
          );
          const newCmPid = newPidForH(hContentMedia);
          if (!existingCm) {
            // le cm n'est pas encore dans le form (quasi sûr), on le crée
            const cm = { ...bm.content_medium, provisional_id: newCmPid };
            setHContentMedia((prev) => ({
              ...prev,
              [newCmPid.toString()]: cm,
            }));
          }
          body_media_attributes.push({
            id: bm.id,
            position: bm.position || 0,
            content_medium_provisional_id:
              existingCm?.provisional_id || newCmPid,
          });
        });
        const { body_media, ...newBody } = {
          ...resBody,
          body_media_attributes,
          provisional_id: pid,
        };
        setHBodies((prev) => ({ ...prev, [body.provisional_id]: newBody }));
        setTimeout(() => setShowSmartLinkModal(false), 500);
      }
      setSmartLinkLoading(false);
    };

    const onChangeTextArea = (newValue: string) => {
      setHBodies((prev) => ({
        ...prev,
        [body.provisional_id]: { ...body, message: newValue },
      }));
    };

    const { applySelectedStyle } = useTextStyles(
      inputRef,
      body.message || "",
      onChangeTextArea
    );

    const bodyCta = useMemo(
      () => ctaOptions.filter((c) => c.value === body?.link_cta)[0],
      [body?.link_cta]
    );

    const toggleStory = () => {
      setHBodies((prevBodies) => ({
        ...prevBodies,
        [body.provisional_id]: {
          ...prevBodies[body.provisional_id],
          is_story: !prevBodies[body.provisional_id]?.is_story,
        },
      }));
    };

    return (
      <div
        key={`body_provisional_id_${body.provisional_id}_${index}`}
        className={`flex relative z-0 ${
          !isActive && "lg:hidden"
        } flex-col border rounded-b-md rounded-tr-md p-4 relative bg-white z-0`}
      >
        <div
          id="header"
          className="flex justify-between w-full gap-3 lg:justify-end"
        >
          <div className="flex lg:hidden">
            {targetsSelectorRenderer(body.provisional_id)}
          </div>
          <div className="flex justify-between w-full gap-3">
            <div className="flex items-start gap-1 ml-5 lg:ml-0">
              <button
                type="button"
                onClick={() => applySelectedStyle("bold")}
                className="flex items-center justify-center w-6 text-lg font-bold border rounded-md cursor-pointer hover:bg-gray-50"
              >
                B
              </button>
              <button
                type="button"
                onClick={() => applySelectedStyle("italic")}
                className="flex items-center justify-center w-6 text-lg italic border rounded-md cursor-pointer hover:bg-gray-50"
              >
                I
              </button>
            </div>
            <div className="flex">
              <div className="hidden lg:flex">
                {targetsSelectorRenderer(body.provisional_id)}
              </div>

              <Tooltip text="Créer une nouvelle version" position="bottomleft">
                <button
                  className="hidden gap-2 ml-2 btn-neutral lg:flex"
                  type="button"
                  onClick={() => duplicateBody(body)}
                >
                  <GoRepoForked className="size-5" /> Version
                </button>
              </Tooltip>
              {!isLast && (
                <Tooltip text="Supprimer cette version" position="bottomright">
                  <button
                    className="hidden gap-2 ml-2 btn-neutral lg:flex"
                    type="button"
                    onClick={() => deleteBody(body)}
                  >
                    <TrashIcon className="size-5" /> Supprimer
                  </button>
                </Tooltip>
              )}
              <CustomPopover
                position="left"
                button={
                  <button
                    className="p-0 btn-neutral lg:hidden h-fit"
                    type="button"
                  >
                    <EllipsisVerticalIcon className="size-6" />
                  </button>
                }
              >
                <div className="flex flex-col py-2 -mt-4 bg-white rounded-lg shadow-lg w-fit">
                  <button
                    className="flex items-center gap-2 px-4 py-3 whitespace-nowrap hover:bg-gray-50"
                    type="button"
                    onClick={() => duplicateBody(body)}
                  >
                    <GoRepoForked className="size-5" /> Créer une nouvelle
                    version
                  </button>
                  <button
                    type="button"
                    className="flex gap-2 px-4 py-3 text-start hover:bg-gray-50"
                  >
                    {targetsSelectorRenderer(
                      body.provisional_id,
                      <div className="flex gap-2 text-start whitespace-nowrap">
                        <UsersIcon className="size-5 " />
                        Sélection des réseaux sociaux
                      </div>
                    )}
                  </button>
                  {!isLast && (
                    <button
                      className="flex gap-2 px-4 py-3 text-start hover:bg-gray-50"
                      type="button"
                      onClick={() => deleteBody(body)}
                    >
                      <TrashIcon className="size-5 " /> Supprimer cette version
                    </button>
                  )}
                </div>
              </CustomPopover>
            </div>
          </div>
        </div>
        <div className="relative flex flex-col w-full my-2">
          <textarea
            ref={inputRef}
            name={`bodies[${index}][message]`}
            placeholder="Saisissez votre texte ici"
            className="w-full placeholder-gray-400 border-0 focus:ring-0"
            //required
            rows={10}
            value={body.message}
            onChange={(e) => {
              setHBodies((prev) => ({
                ...prev,
                [body.provisional_id]: { ...body, message: e.target.value },
              }));
            }}
          />
        </div>
        {/* hidden inputs */}
        <input
          type="hidden"
          name={`bodies[${index}][id]`}
          value={body.id || ""}
        />
        <input
          type="hidden"
          name={`bodies[${index}][provisional_id]`}
          value={body.provisional_id}
        />
        <BodyMediaInputs
          prefix={`bodies[${index}][body_media_attributes]`}
          originalCmPidByBmId={
            body.id === originalId ? originalCmPidByBmId : {}
          }
          bodyMedia={body.body_media_attributes}
        />
        <div className="flex flex-wrap mb-4">
          {/* Content medias - Uploader + Drag&Drop + Displaying Medias */}
          <ContentMediasUploader
            open={openCmModal}
            setOpen={setOpenCmModal}
            accept={accept}
            disabled={disabled}
            hContentMedia={hContentMedia}
            setHContentMedia={setHContentMedia}
            selectedContentMedias={body.body_media_attributes?.map(
              (bma) => bma?.content_medium_provisional_id
            )}
            beforeRemoveFn={(contentMediaId: number) =>
              beforeRemoveFn(contentMediaId, body.provisional_id)
            }
            afterAddFn={(contentMediaIds: number[]) =>
              afterAddFn(contentMediaIds, body.provisional_id)
            }
            afterMoveFn={(contentMediaId: number, newPosition: number) =>
              afterMoveFn(contentMediaId, newPosition, body.provisional_id)
            }
          />
        </div>
        {/* Media Modal */}
        <Modal open={openMediaModal} setOpen={setOpenMediaModal}>
          <div className="relative bg-white rounded-lg">
            <MediaModal
              defaultSearch={defaultPictureSearch}
              onImageSelect={setOpenMediaModal}
              setHContentMedia={setHContentMedia}
              canvaSession={canvaSession}
              profile_picture={profile_picture}
              hContentMedia={hContentMedia}
              afterAddFn={(contentMediaIds: number[]) =>
                afterAddFn(contentMediaIds, body.provisional_id)
              }
              onClose={() => setOpenMediaModal(false)}
            />
          </div>
        </Modal>
        {/* Links Displaying */}
        {body?.link_url && (
          <div className="relative flex flex-col gap-2 px-3 py-4 mt-1 mb-4 bg-gray-100  group max-w-80 rounded-b-md">
            <button
              type="button"
              className={`flex md:hidden group-hover:flex absolute -top-2 -right-2 justify-center items-center border w-6 h-6 border-red-400 text-xs text-red-400 bg-white rounded-full cursor-pointer !hover:bg-red-400 !hover:text-white hover:bg-red-400`}
              onClick={removeLink}
            >
              <XMarkIcon className="w-4 h-4 text-red-500 hover:text-white" />
            </button>
            <p className="font-bold text-gray-600 truncate">
              {body?.link_url &&
                (() => {
                  try {
                    return new URL(body?.link_url).hostname;
                  } catch {
                    return "";
                  }
                })()}
            </p>
            <p className="text-sm text-gray-800 truncate">
              {body?.link_description || ""}
            </p>
            {bodyCta?.value && body?.link_cta && (
              <span className="absolute bottom-2 right-2 btn-neutral !px-2 !py-1 flex text-sm">
                {bodyCta.label}
              </span>
            )}
          </div>
        )}
        {/* Editing Features */}
        <div className="relative flex items-center justify-between gap-5">
          <div className="relative flex items-center justify-start gap-2 sm:gap-3">
            {/* Body Link button */}
            <BodyLink
              body={body}
              updateBody={updateBody}
              disabled={["images", "videos"].includes(focus)}
            />
            <input
              type="hidden"
              name={`bodies[${index}][link_url]`}
              value={body?.link_url ?? ""}
            />
            <input
              type="hidden"
              name={`bodies[${index}][link_description]`}
              value={body?.link_description ?? ""}
            />
            <input
              type="hidden"
              name={`bodies[${index}][link_cta]`}
              value={body?.link_cta ?? ""}
            />

            {/* Button Content medias */}
            <Tooltip text="Insérer images ou vidéo" position="bottom">
              <button
                type="button"
                className="flex items-center justify-center bg-gray-100 rounded-full text-brand_main size-9 hover:bg-gray-200"
                onClick={() => !disabled && setOpenCmModal(true)}
              >
                <PhotoIcon
                  className={`size-7 ${
                    disabled
                      ? "text-gray-400 hover:text-gray-400"
                      : "hover:text-brand_darker"
                  }`}
                />
              </button>
            </Tooltip>

            <Tooltip text="Choisir une image libre de droit" position="bottom">
              <button
                type="button"
                className="flex p-1 bg-gray-100 rounded-full text-brand_main size-9 hover:bg-gray-200"
                onClick={() => !disabled && setOpenMediaModal(true)}
              >
                <BeakerIcon
                  className={`size-7 ${
                    disabled
                      ? "text-gray-400 hover:text-gray-400"
                      : "hover:text-brand_darker"
                  }`}
                />
              </button>
            </Tooltip>

            <Tooltip text="Insérer des émojis dans le texte" position="bottom">
              <button
                type="button"
                onClick={() => setEmojiSelectorOpen(true)}
                className="flex items-center justify-center bg-gray-100 rounded-full text-brand_main size-9 hover:bg-gray-200"
              >
                <FaceSmileIcon className="size-7" />
              </button>
            </Tooltip>

            <div
              className={`${
                emojiSelectorOpen ? "" : "hidden"
              } absolute -bottom-12 origin-bottom border rounded !z-50 h-96 max-h-96 min-h-96 overflow-auto`}
            >
              <EmojiPicker
                perLine={9}
                maxFrequentRows={1}
                previewPosition="bottom"
                navPosition="top"
                searchPosition="top"
                theme="light"
                onClickOutside={() => {
                  emojiSelectorOpen ? setEmojiSelectorOpen(false) : null;
                }}
                onEmojiSelect={onEmojiSelect}
              />
            </div>
            <div className="relative">
              <Tooltip
                text="Génération du contenu grâce à l'intelligence artificielle"
                position="bottom"
              >
                <button
                  type="button"
                  className="relative flex items-center justify-center text-base bg-gray-100 rounded-full text-brand_main size-9 hover:bg-gray-200"
                  onClick={() => {
                    setShowAiModal(!showAiModal);
                  }}
                >
                  IA
                </button>
              </Tooltip>
              <Modal open={showAiModal} setOpen={setShowAiModal}>
                <div className="relative p-12 my-auto bg-white rounded-md">
                  <CustomCloseButton
                    open={showAiModal}
                    setOpen={setShowAiModal}
                  />
                  <ModalInput
                    title="AI Generation"
                    value={prompt}
                    message="Entrez le sujet de votre article, donnez des détails sur le contenu que vous souhaitez créer. (limite 30 par mois.)"
                    placeholder="Exemple: 'Rédigez un article sur les avantages de vivre à Paris 17ème.'"
                    setValue={setPrompt}
                    loading={gptIsWriting}
                    error={gptError}
                    Submit={() => fetchChatGPTResponse(prompt)}
                  />
                </div>
              </Modal>
            </div>
            <div className="relative">
              <CustomPopover
                position="top"
                button={
                  <Tooltip
                    text="Insérer des variables personnelles"
                    position="bottom"
                  >
                    <button
                      type="button"
                      className="relative flex items-center justify-center text-base bg-gray-100 rounded-full text-brand_main size-9 hover:bg-gray-200"
                    >
                      $V
                    </button>
                  </Tooltip>
                }
                panelClasses="drop-shadow-sm"
              >
                <div
                  className={`flex flex-col py-2 border rounded bg-white h-32 overflow-y-auto`}
                >
                  {insertionVariables.map((variable) => (
                    <div
                      key={`variable_${variable}`}
                      className="px-4 py-1 text-sm font-medium text-gray-700 cursor-pointer hover:text-brand_main hover:bg-gray-100"
                      onClick={() => insert(variable)}
                    >
                      {variable}
                    </div>
                  ))}
                </div>
              </CustomPopover>
            </div>
            <div>
              <Tooltip
                text="Construire un contenu à partir d'une URL"
                position="bottomright"
              >
                <button
                  type="button"
                  className="flex items-center justify-center text-xs bg-gray-100 rounded-full text-brand_main size-9 hover:bg-gray-200"
                  onClick={toggleSmartlinkModal}
                >
                  <CloudArrowDownIcon className="size-6" />
                </button>
              </Tooltip>
              <Modal open={showSmartLinkModal} setOpen={setShowSmartLinkModal}>
                <div className="relative p-12 my-auto bg-white rounded-md">
                  <CustomCloseButton
                    open={showSmartLinkModal}
                    setOpen={setShowSmartLinkModal}
                  />
                  <ModalInput
                    title="Smartlink"
                    value={smartLinkUrl}
                    message="Entrez l'url d'un article et nous écrirons pour vous une accroche, cela remplacera le contenu actuel."
                    placeholder="https://example.com/article"
                    setValue={setSmartLinkUrl}
                    loading={smartLinkLoading}
                    error={smartLinkError}
                    Submit={replaceBodyWithRemote}
                  />
                </div>
              </Modal>
            </div>
          </div>
          {/* Toogle for isStory input */}
          <div className="relative flex items-center gap-2">
            <span className="whitespace-nowrap w-fit text-gray-500 font-normal text-sm">
              Publier en Story
            </span>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                onChange={() => toggleStory()}
                role="switch"
                checked={body.is_story}
                name={`bodies[${index}][is_story]`}
                id={`bodies[${index}][is_story]`}
                value={body.is_story ? 1 : 0}
              />

              <div
                className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none
                        peer-focus:ring-brand_focus dark:peer-focus:ring-brand_focus rounded-full
                        peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full
                        peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px]
                        after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full
                        after:h-5 after:w-5 after:transition-all peer-checked:bg-green-300"
              ></div>
            </label>
          </div>
        </div>
      </div>
    );
  }
);

const BodyMediaInputs = memo(
  ({
    originalCmPidByBmId,
    bodyMedia,
    prefix,
  }: {
    originalCmPidByBmId: Record<number, number>;
    bodyMedia: ProvisionalBody["body_media_attributes"];
    prefix: string;
  }) => {
    // originalCmPidByBmId permet de réinstaurer les mêmes ids si même content_media
    // mais cas des smartlinks ou l'id existe mais n'était pas présent au début
    // donc on check originalCmPidByBmId[cmPid] || id
    const contentMediaPids = bodyMedia.map(
      (bma) => bma.content_medium_provisional_id
    );
    const destroyables = Object.entries(originalCmPidByBmId).filter(
      ([bm, _]) => !contentMediaPids.includes(parseInt(bm))
    );
    const destIndex = bodyMedia.length + 1;

    return (
      <>
        {" "}
        {bodyMedia.map(
          ({ content_medium_provisional_id: cmPid, position, id }, i) => (
            <div key={cmPid}>
              <HiddenInput
                name={`${prefix}[${i}][id]`}
                value={originalCmPidByBmId[cmPid] || id}
              />
              <HiddenInput
                name={`${prefix}[${i}][content_medium_provisional_id]`}
                value={cmPid}
              />
              <HiddenInput
                name={`${prefix}[${i}][position]`}
                value={position}
              />
            </div>
          )
        )}
        {destroyables.map(([c, pid], i) => (
          <div key={c}>
            <HiddenInput name={`${prefix}[${i + destIndex}][id]`} value={pid} />
            <HiddenInput
              name={`${prefix}[${i + destIndex}][_destroy]`}
              value={1}
            />
          </div>
        ))}
      </>
    );
  }
);

export default BodyEditor;
