import React, { useEffect, useState } from "react";
import useMultiCloudinaryUpload from "../useMultiCloudinaryUpload";
import {
  CanvaPage,
  LibraryImage,
  PixabayImage,
  ProvisionalContentMedium,
} from "../../types";
import { GlobeAltIcon, PhotoIcon } from "@heroicons/react/24/outline";
import { SiCanva } from "react-icons/si";
import CustomCloseButton from "../CustomCloseButton";
import PixabayTab from "./PixabayTabs";
import MediaLibraryTab from "./MediaLibraryTabs";
import CanvaTab from "./CanvaTab";
import { usePixabaySearch } from "../../hook/usePixabaySearch";
import { useCanva } from "../../hook/useCanva";
import CanvaLogo from "../../../assets/images/CanvaIcon.png"

interface MediaModalProps {
  onImageSelect: React.Dispatch<React.SetStateAction<boolean>>;
  hContentMedia: Record<number, ProvisionalContentMedium>;
  profile_picture: string;
  canvaSession: any;
  setHContentMedia: React.Dispatch<React.SetStateAction<Record<number, ProvisionalContentMedium>>>;
  afterAddFn: (contentMediaIds: number[]) => void;
  defaultSearch: string | null;
}

const MediaModal: React.FC<MediaModalProps> = ({
  onImageSelect,
  defaultSearch,
  hContentMedia,
  profile_picture,
  canvaSession,
  setHContentMedia,
  afterAddFn,
}) => {
  const [query, setQuery] = useState("");
  const [images, setImages] = useState<PixabayImage[]>([]);
  const [mediaLibrary, setMediaLibrary] = useState<LibraryImage[] | null>(null);

  // États liés à Canva
  const [canvaSessionState, setCanvaSessionState] = useState(() => canvaSession);
  const [folders, setFolders] = useState<any[]>([]);
  const [rootFolder, setRootFolder] = useState<any | null>(null);
  const [rootFolderContinuationString, setRootFolderContinuationString] = useState<string | null>(null);
  const [foldersLoading, setFoldersLoading] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const [folderContinuation, setFolderContinuation] = useState<string | null>(null);
  const [selectedFolderItems, setSelectedFolderItems] = useState<any[]>([]);
  const [refreshTokenRevokedMessage, setRefreshTokenRevokedMessage] = useState<string | null>(null);
  const [isRootSelected, setIsRootSelected] = useState<boolean>(true);
  const [selectedCanvaDesign, setSelectedCanvaDesign] = useState<any | null>(null);
  const [selectedCanvaPages, setSelectedCanvaPages] = useState<CanvaPage[]>([]);
  // États liés à Pixabay
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<PixabayImage | LibraryImage | null>(null);
  const [insertLogo, setInsertLogo] = useState(false);
  const [page, setPage] = useState(1);
  const [isSaving, setIsSaving] = useState(false);

  const positions = [
    "top-left",
    "top-center",
    "top-right",
    "center-left",
    "center",
    "center-right",
    "bottom-left",
    "bottom-center",
    "bottom-right",
  ] as const;
  const [logoPosition, setLogoPosition] = useState<(typeof positions)[number]>("top-left");
  const [logoShape, setLogoShape] = useState<"rounded-square" | "circle">("rounded-square");
  const [selectedLogo, setSelectedLogo] = useState("rounded-square");
  const [activeTab, setActiveTab] = useState<"search" | "library" | "canvas">("search");

  const { uploadFiles } = useMultiCloudinaryUpload({
    addMedia: (files) => {
      const lastCMId = Math.max(...Object.keys(hContentMedia).map((k) => parseInt(k, 10)), 0) + 1;
      const newCMs = files.map((file, index) => {
        const newId = lastCMId + index;
        return [newId, { provisional_id: newId, serialized_file: file }];
      });
      setHContentMedia((prev) => ({ ...prev, ...Object.fromEntries(newCMs) }));
      afterAddFn(newCMs.map((c) => c[0]) as number[]);
      onImageSelect(false);
    },
  });

  const { loadMoreRootFolder, fetchRootFolder, fetchAllFolders, fetchFolderItems, fetchDesignPages } = useCanva(
    canvaSessionState,
    isRootSelected,
    rootFolderContinuationString,
    setRootFolderContinuationString,
    setRootFolder,
    setFoldersLoading,
    setCanvaSessionState,
    setRefreshTokenRevokedMessage,
    setFolders,
    setFolderContinuation,
    setSelectedCanvaPages,
    setSelectedFolderItems
  );

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch("/content_media", {
          headers: { Accept: "application/json" },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setMediaLibrary(data.content_media.map((cm: any) => cm.serialized_file));
      } catch (error) {
        console.error("Error fetching media library:", error);
      }
    };
    fetchImages();
  }, []);

  useEffect(() => {
    if (activeTab === "canvas") {
      fetchRootFolder();
      fetchAllFolders();
      setIsRootSelected(true); 
      setSelectedFolderItems([]);
      setSelectedCanvaPages([]);
      setSelectedCanvaDesign(null);
      setSelectedImage(null);
    }
  }, [activeTab]);
  useEffect(() => {
    if (isRootSelected) {
      setSelectedFolderItems(rootFolder || []);
    }
  }, [isRootSelected, rootFolder]);

  useEffect(() => {
    if (activeTab === "search" || activeTab === "library") {
      setInsertLogo(false);
    }
  }, [activeTab]);

  const {
    uploadSelectedImage,
    searchImages,
    loadMoreImages,
    getPositionClasses,
    getShapeClasses,
  } = usePixabaySearch(
    profile_picture,
    setIsSaving,
    uploadFiles,
    activeTab,
    defaultSearch,
    query,
    selectedCanvaDesign,
    setQuery,
    page,
    setPage,
    setLoading,
    setImages,
    selectedCanvaPages,
    insertLogo,
    selectedImage,
    logoPosition,
    logoShape
  );

  return (
    <div
      className="fixed inset-0 flex justify-center items-center bg-gray-700 bg-opacity-50 z-50"
      onClick={() => !isSaving && onImageSelect(false)}
    >
      <div
        className="relative flex flex-col p-4 border border-gray-300 rounded-lg bg-white shadow-lg w-[768px] lg:min-w-3xl lg:max-w-3xl h-5/6 lg:h-3/4 overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <CustomCloseButton onClickEvent={() => !isSaving && onImageSelect(false)} />
        <div className="flex mb-4 gap-4">
          <button
            className={`flex gap-2 items-center text-center whitespace-nowrap border-b-2 p-4 text-sm font-medium ${
              activeTab === "search"
                ? "border-indigo-500 text-indigo-500"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-b-2 hover:border-gray-400"
            }`}
            onClick={() => setActiveTab("search")}
          >
            <GlobeAltIcon className="size-8 sm:size-5" />
            <p className="hidden sm:inline truncate">Banque d'image</p>
          </button>
          <button
            className={`flex gap-2 items-center text-center whitespace-nowrap border-b-2 p-4 text-sm font-medium ${
              activeTab === "library"
                ? "border-indigo-500 text-indigo-500"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-b-2 hover:border-gray-400"
            }`}
            onClick={() => setActiveTab("library")}
          >
            <PhotoIcon className="size-8 sm:size-5" />
            <p className="hidden sm:inline truncate">Ma bibliothèque</p>
          </button>
          <button
            className={`flex gap-2 items-center text-center whitespace-nowrap border-b-2 p-4 text-sm font-medium ${
              activeTab === "canvas"
                ? "border-indigo-500 text-indigo-500"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-b-2 hover:border-gray-400"
            }`}
            onClick={() => setActiveTab("canvas")}
          >
            <img src={CanvaLogo} className="size-8 sm:size-5" />
            <p className="hidden sm:inline truncate">Mes designs Canva</p>
          </button>
        </div>

        {activeTab === "search" && (
          <PixabayTab
            query={query}
            setQuery={setQuery}
            images={images}
            setImages={setImages}
            loading={loading}
            page={page}
            setPage={setPage}
            defaultSearch={defaultSearch}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            insertLogo={insertLogo}
            setInsertLogo={setInsertLogo}
            logoPosition={logoPosition}
            setLogoPosition={setLogoPosition}
            logoShape={logoShape}
            setLogoShape={setLogoShape}
            selectedLogo={selectedLogo}
            setSelectedLogo={setSelectedLogo}
            isSaving={isSaving}
            searchImages={searchImages}
            loadMoreImages={loadMoreImages}
            uploadSelectedImage={uploadSelectedImage}
            profile_picture={profile_picture}
            getPositionClasses={getPositionClasses}
            getShapeClasses={getShapeClasses}
            onImageSelect={onImageSelect}
          />
        )}

        {activeTab === "library" && (
          <MediaLibraryTab
            mediaLibrary={mediaLibrary}
            loading={loading}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            insertLogo={insertLogo}
            setInsertLogo={setInsertLogo}
            logoPosition={logoPosition}
            setLogoPosition={setLogoPosition}
            logoShape={logoShape}
            setLogoShape={setLogoShape}
            selectedLogo={selectedLogo}
            setSelectedLogo={setSelectedLogo}
            isSaving={isSaving}
            uploadSelectedImage={uploadSelectedImage}
            onImageSelect={onImageSelect}
            profile_picture={profile_picture}
            getPositionClasses={getPositionClasses}
            getShapeClasses={getShapeClasses}
          />
        )}

        {activeTab === "canvas" && (
          <>
            <CanvaTab
              refreshTokenRevokedMessage={refreshTokenRevokedMessage}
              folders={folders}
              foldersLoading={foldersLoading}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
              selectedFolderItems={selectedFolderItems}
              selectedCanvaDesign={selectedCanvaDesign}
              setSelectedCanvaDesign={setSelectedCanvaDesign}
              selectedCanvaPages={selectedCanvaPages}
              setSelectedCanvaPages={setSelectedCanvaPages}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              isSaving={isSaving}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              fetchFolderItems={fetchFolderItems}
              fetchDesignPages={fetchDesignPages}
              onImageSelect={onImageSelect}
              uploadSelectedImage={uploadSelectedImage}
              loadMoreRootFolder={loadMoreRootFolder}
              fetchRootFolder={fetchRootFolder}
              rootFolderContinuationString={rootFolderContinuationString}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MediaModal;