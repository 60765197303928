import { CanvaPage } from "../types";

export const useCanva = (
  canvaSessionState: any,
  isRootSelected: boolean | null,
    rootFolderContinuationString: string | null,
    setRootFolderContinuationString: React.Dispatch<React.SetStateAction<string | null>>,
    setRootFolder: React.Dispatch<any>,
    setFoldersLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setCanvaSessionState: React.Dispatch<any>,
    setRefreshTokenRevokedMessage: React.Dispatch<React.SetStateAction<string | null>>,
    setFolders: React.Dispatch<React.SetStateAction<any[]>>,
    setFolderContinuation: React.Dispatch<React.SetStateAction<string | null>>,
    setSelectedCanvaPages: React.Dispatch<React.SetStateAction<CanvaPage[]>>,
    setSelectedFolderItems: React.Dispatch<React.SetStateAction<any[]>>
) => {
      const refreshAccessToken = async () => {
          try {      
            const response = await fetch("/canva/refresh");
            if (response.ok) {
              const data = await response.json();         
              setCanvaSessionState((prev: any) => ({ ...prev, access_token: data.access_token }));        
            } else {
              console.error("Failed to refresh Canva access token");
              setRefreshTokenRevokedMessage("Votre session Canva a expiré. Veuillez reconnecter votre compte.");
            }
          } catch (error) {
            console.error("Error refreshing access token:", error);
          }
      };
      const fetchAllFolders = async () => {
          setFoldersLoading(true);
          try {      
            const response = await fetch(
              "https://api.canva.com/rest/v1/folders/root/items?item_types=folder",
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${canvaSessionState.access_token}`,
                },
              }
            );
            if (response.status === 401) {
              await refreshAccessToken();
            }
            const data = await response.json();
            setFolders(data.items || []);          
            setFolderContinuation(data.continuation || null);
          } catch (error) {
            console.error("Error fetching root folders:", error);
          } finally {
            setFoldersLoading(false);
          }
      };
      const fetchRootFolder = async () => {
        try {
          const response = await fetch(
            "https://api.canva.com/rest/v1/folders/root/items?item_types=design",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${canvaSessionState.access_token}`,
              },
            }
          );
          if (response.status === 401) {
            await refreshAccessToken();
          }
          const data = await response.json();
          setRootFolderContinuationString(data.continuation || null);
          setRootFolder(data.items || []);
        } catch (error) {
          console.error("Error fetching root folders:", error);
        }
      };
      const loadMoreRootFolder = async () => {
        if (!rootFolderContinuationString) return;
        try {
          const response = await fetch(
            `https://api.canva.com/rest/v1/folders/root/items?item_types=design&continuation=${rootFolderContinuationString}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${canvaSessionState.access_token}`,
              },
            }
          );
          if (response.status === 401) {
            refreshAccessToken();
            return;
          }
          const data = await response.json();
          setRootFolder((prev: any) => [...(prev || []), ...(data.items || [])]);
          setRootFolderContinuationString(data.continuation || null);
          if (isRootSelected) {
            setSelectedFolderItems((prev) => [...prev, ...(data.items || [])]);
          }
        } catch (error) {
          console.error("Error loading more root folder items:", error);
        }
      };    
      const fetchFolderItems = async (folderId: string) => {
        try {      
          const response = await fetch(
            `https://api.canva.com/rest/v1/folders/${folderId}/items?item_types=design`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${canvaSessionState.access_token}`,
              },
            }
          );
          if (response.status === 401) {
            await refreshAccessToken();
          }
          const data = await response.json();
          setSelectedFolderItems(data.items || []);
          setFolderContinuation(data.continuation || null);
        } catch (error) {
          console.error("Error fetching folder items:", error);
        } 
      };
      const fetchDesignPages = async (designId: string) => {
        try {
          const response = await fetch(
            `https://api.canva.com/rest/v1/designs/${designId}/pages?limit=50`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${canvaSessionState.access_token}`,
              },
            }
          );
          const data = await response.json();
          setSelectedCanvaPages(data.items || []);
        } catch (error) {
          console.error("Error fetching design pages:", error);
        }
      };
  return {
    loadMoreRootFolder,
    fetchRootFolder,
    fetchAllFolders,
    fetchFolderItems,
    fetchDesignPages
  }
}